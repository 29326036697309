import { EventAreaModal } from './EventAreaModal'
import { ScheduleEventAreaModal } from './ScheduleEventAreaModal'
import { ScheduleSeachForm } from './ScheduleSearchForm'
import { ScheduleSchoolSearchForm } from './ScheduleSchoolSearchForm'
import { ScheduleTag } from './ScheduleTag'

window.addEventListener('DOMContentLoaded', function(){
  // /events/~ JS読み込み
  if(window.location.pathname.includes('events')){
    const eventAreaModal = new EventAreaModal()
    eventAreaModal.setSubmitEvent()
  }

  // /schedules/~ JS読み込み
  if(window.location.pathname.includes('schedules')){
    const scheduleEventAreaModal = new ScheduleEventAreaModal()
    const scheduleSearchForm = new ScheduleSeachForm()
    const scheduleSchoolSearchForm = new ScheduleSchoolSearchForm()
    const scheduleTag = new ScheduleTag()

    // スケジュールページ エリアモーダル イベントの設置
    scheduleEventAreaModal.setScheduleEventAreaModal()

    // スケジュールページ 検索フォーム イベントの設置
    scheduleSearchForm.setEventSelectBox()

    // スケジュールページ 学校名検索フォーム イベントの設置
    scheduleSchoolSearchForm.setSearchSchoolEvent()

    // スケジュールタグ 削除ボタン イベントの設置
    scheduleTag.removeTag()
  }
})