import Qs from 'qs'

export class QueryParamsManager {
  constructor(){
    this.queryParams = Qs.parse(window.location.search.substr(1))
  }

  // QueryStringが存在する場合 true 存在しない場合 false
  isExistedQueryParams(){ return this.queryParams.q != undefined }

  // QueryStringが存在する場合
  // Groupingされている場合 true Groupingされていない場合 false
  isGroupedQueryParams(){ return 'g' in this.queryParams.q }
  
  // window.location.hrefに直接入れる際に使用する
  groupingQueryParamsValidChecker(){
    if(!this.isExistedQueryParams()) return 
    if(!this.isGroupedQueryParams()) return
    if(this.isGroupedQueryParams()) {
      const groupingValidQuery = () => { return 'g' in this.queryParams.q && '0' in this.queryParams.q }
      if(!groupingValidQuery()) this.queryParams = { q: this.queryParams.q.g[0] }
    }
  }

  queryParamsIntegrator(date, params){
    if(!this.isExistedQueryParams()) {
      return this.createQueryParams(date, params)
    }
    if(!this.isGroupedQueryParams()) {
      return this.assignQueryParams(date, params)
    }
    if(this.isGroupedQueryParams()) {
      return this.assignGroupingQueryParams(date, params)
    }
  }

  queryParamsfilter(target){
    if(!this.isExistedQueryParams()) {
      return []
    }
    if(!this.isGroupedQueryParams()) {
      if(target in this.queryParams.q) {
        return this.queryParams.q[target]
      }
      return []
    }
    if(this.isGroupedQueryParams()) {
      if(target in this.queryParams.q.g['0']) {
        return this.queryParams.q.g['0'][target]
      }
      return []
    }
  }

  queryParamsTranslator(target, targetValue){
    if(!this.isExistedQueryParams()) {
      return []
    }
    if(!this.isGroupedQueryParams()){
      if(target in this.queryParams.q) {
        this.queryParams.q[target] = this.queryParams.q[target].filter( value => value !== targetValue)
        return this.queryParams
      }
      return []
    }
    if(this.isGroupedQueryParams()) {
      if(target in this.queryParams.q.g['0']) {
        this.queryParams.q.g['0'][target] = this.queryParams.q.g['0'][target]( value => value != targetValue)
        return this.queryParams
      }
      return []
    }
  }

  buildUrl(url = this.host, params = this.queryParams){
    return `${url}?${Qs.stringify(params, {arrayFormat: 'brackets' })}`
  }

  buildQuery(params = this.queryParams){
    return `?${Qs.stringify(params, {arrayFormat: 'brackets' })}`
  }
  
  createQueryParams(date, params){ 
    return { date: date, q: params }
  }

  assignQueryParams(date, params){ 
    return { date: date, q: Object.assign(this.queryParams.q, params) }
  }

  assignGroupingQueryParams(date, params){
    return { date: date, q: Object.assign(this.queryParams.q.g['0'], params)}
  }
}