import { QueryParamsManager } from './QueryParamsManager'
import { UAParser } from 'ua-parser-js'

export class ScheduleSeachForm {
  constructor(){
    this.queryParamsManager = new QueryParamsManager()
    this.host = `${window.location.protocol}//${window.location.host}`
    this.participantTypeSelectBox = document.getElementById('schedule_event_select_participant_type')
    this.timeSelectBox = document.getElementById('schedule_event_time')
    this.date = document.getElementById('schedule-event-date').value
  }

  setEventSelectBox(){
    this.setParticipantTypeEvent()
    this.setTimeEvent()
  }

  setParticipantTypeEvent(){
    if(this.participantTypeSelectBox){
      this.setSubmitEventParticipantType()
      this.reflectResultParticipantTypeSelectBox()
    }
  }

  setSubmitEventParticipantType(){    
    const pathname = window.location.pathname
    this.participantTypeSelectBox.addEventListener('change', (e) => {
      const params = { participant_type_eq_any: e.target.value }
      const newParams = this.queryParamsManager.queryParamsIntegrator(this.date, params)
      window.location.href = this.queryParamsManager.buildUrl(`${this.host}${pathname}`, newParams)
    })
  }

  reflectResultParticipantTypeSelectBox() {    
    const value = this.queryParamsManager.queryParamsfilter('participant_type_eq_any')[0]
    if(value === '') return this.participantTypeSelectBox.options[''] = true
    if(value) return this.participantTypeSelectBox.options[value].selected = true
  }

  setTimeEvent(){
    if(this.timeSelectBox){
      const ua = new UAParser()
      const uaType = ua.getDevice().type
      this.setSubmitTimeSelectBox()
      if(uaType === 'mobile' || uaType === 'tablet'){
        this.reflectResultTimeSelectBoxMobile()
      } else {
        this.reflectResultTimeSelectBoxPC()
      }
    }
  }

  setSubmitTimeSelectBox(){    
    this.timeSelectBox.addEventListener('change', (e) => {
      this.queryParamsManager.groupingQueryParamsValidChecker()
      const queryParams = this.queryParamsManager.buildQuery()
      this.reflectResult(this.host, queryParams, e.target.value)
    })
  }

  // TODO: 0: 月, 1: 週, 2: 日 valueで判定するように修正する
  reflectResultTimeSelectBoxPC(){
    const currentUrl = window.location.href
    if(currentUrl.includes('/schedules/month')) this.timeSelectBox.options[0].selected = true
    if(currentUrl.includes('/schedules/week')) this.timeSelectBox.options[1].selected = true
    this.timeSelectBox.options[2].selected = true
  }

  reflectResultTimeSelectBoxMobile(){
    const currentUrl = window.location.href
    if(currentUrl.includes('/schedules/month')){
      this.timeSelectBox.options[1].selected = true
    } else {
      this.timeSelectBox.options[0].selected = true
    }
  }

  isEmpty(object){
    Object.keys(object).length === 0
  }

  reflectResult(host, query, value){
    switch(value){
    case 'month':
      return window.location.href = `${host}/schedules/month${query}`
    case 'week':
      return window.location.href = `${host}/schedules/week${query}`
    }
  }
}