import { SchoolNameSearcher } from './SchoolNameSearcher'
import { QueryParamsManager } from './QueryParamsManager'

export class ScheduleSchoolSearchForm {
  constructor(){
    this.searchForm = document.getElementById('schedule_event_school_search_form')
    this.searchFormBtn = document.getElementById('schedule_event_school_search_form_btn')
    this.appendTarget = document.getElementById('school_search_result')
    this.date = document.getElementById('schedule-event-date').value
    this.selected_school_ids = []
  }

  setSearchSchoolEvent(){
    if(this.searchForm){
      this.initLoad()
      this.setSearchFormEvent()
      this.setSubmitEvent()
    }
  }

  initLoad(){
    const queryParamsManager = new QueryParamsManager()
    this.selected_school_ids = queryParamsManager.queryParamsfilter('schools_id_or_organizer_id_in').map(id => Number(id))
    this.appendSchoolName(this.appendTarget, '')
  }

  setSearchFormEvent(){
    this.searchForm.addEventListener('input', (e) => {
      this.appendSchoolName(this.appendTarget, e.target.value)
    })
  }

  async appendSchoolName(appendTarget, inputValue){
    const results = await new SchoolNameSearcher().call(inputValue)
    appendTarget.innerHTML = ''
    results.forEach((school) => {
      const element = this.createElements(school)
      appendTarget.append(element)
      this.setEventSchoolElement(element)
    })
  }

  setEventSchoolElement(element){
    const self = this
    element.addEventListener('click', function(){
      const selected_school_id = Number(this.getAttribute('value'))
      if(Array.from(this.classList).includes('is-sel')){
        this.setAttribute('class', 'schedule-search__item')
        self.selected_school_ids = self.selected_school_ids.filter(id => id !== selected_school_id)
      } else {
        this.setAttribute('class', 'schedule-search__item is-sel')
        self.selected_school_ids.push(selected_school_id)
      }
    })
  }

  createElements(school){
    const pTag = this.createSchoolElement(school)
    const aTag = this.createSchoolLink(school)
    aTag.append(school.name)
    pTag.append(aTag)
    return pTag
  }

  createSchoolElement(school){
    const element = document.createElement('p')
    if(this.selected_school_ids.includes(school.id)){
      element.className = 'schedule-search__item is-sel'
    } else {
      element.className = 'schedule-search__item'
    }
    element.setAttribute('value', school.id)
    return element
  }

  createSchoolLink(){
    const element = document.createElement('a')
    return element
  }

  setSubmitEvent(){
    const host = window.location.href.split('?')[0]
    const queryParamsManager = new QueryParamsManager()
    this.searchFormBtn.addEventListener('click', () => {
      const params = { schools_id_or_organizer_id_in: this.selected_school_ids }
      const newParams = queryParamsManager.queryParamsIntegrator(this.date, params)
      window.location.href = queryParamsManager.buildUrl(host, newParams)
    })
  }
}