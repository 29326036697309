import { axios } from '../axios'
import Qs from 'qs'

export class SchoolNameSearcher {
  constructor(){
    this.results = []
  }
  
  async call(value){
    await axios.get('/api/schools', {
      params: {
        q: {
          name_cont: value
        }
      },
      paramsSerializer: function(params) {
        return Qs.stringify(params, {arrayFormat: 'brackets'})
      }
    }).then(res => this.results = res.data)
    return this.results
  }
}