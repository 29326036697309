import axiosBase from 'axios'

const url = `${window.location.protocol}//${window.location.host}`

export const axios = axiosBase.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: 'json'
})