export class EventAreaModal {
  constructor(){
    this.searchForm = document.getElementById('event_search_form')
    this.searchFormBtn = document.getElementsByClassName('event_search_form_btn')
    this.searchFormSp = document.getElementById('event_search_form_sp')
    this.searchFormSpBtn = document.getElementsByClassName('event_search_form_sp_btn')
    this.childElements = document.getElementsByClassName('school_area')
  }

  setSubmitEvent(){
    this.setSubmitEventSearchForm()
    this.setSubmitEventSearchFormSp()
    this.setEventChildElements()
  }

  setSubmitEventSearchForm(){
    Array.from(this.searchFormBtn).forEach((element) => {
      element.addEventListener('click', () => this.searchForm.submit() )
    })
  }

  setSubmitEventSearchFormSp(){
    Array.from(this.searchFormSpBtn).forEach((element) => {
      element.addEventListener('click', () => this.searchFormSp.submit() )
    })
  }

  setEventChildElements(){
    Array.from(this.childElements).forEach((element) => {
      element.addEventListener('click', (e) => {
        const parent = e.target.parentNode
        if (Array.from(parent.classList).includes('is-on')) {
          parent.classList.remove('is-on')
          this.searchForm.removeChild(this.searchForm.lastChild)
        } else {
          parent.classList.add('is-on')
          const input = this.createInputTag('hidden', e.target.dataset.region, 'q[prefecture_id_during][]')
          this.searchForm.append(input)
        }
      })
    })
  }

  createInputTag(type, value, name){
    const input = document.createElement('input')
    input.type = type
    input.value = value
    input.name = name
    return input
  }
}