import { QueryParamsManager } from './QueryParamsManager'

export class ScheduleTag {
  constructor(){
    this.removeTagBtn = document.getElementsByClassName('remove_tag')
    this.deleteTarget = document.getElementById('schedule_event_search_target')
  }
  
  removeTag(){
    if(this.removeTagBtn){
      Array.from(this.removeTagBtn).forEach((element) => {
        element.addEventListener('click', (e) => {
          const searchValue = e.target.getAttribute('value')
          const searchName = e.target.getAttribute('name')
          e.target.parentNode.remove()
    
          this.deleteSearchElement(searchValue)
          this.deleteParams(searchValue, searchName)
        })
      })
    }
  }
  
  deleteSearchElement(value){
    Array.from(this.deleteTarget.childNodes).forEach((element) => {
      if(element.type === 'hidden' ){
        if(element.getAttribute('value') === value) element.remove()
      }
    })
  }

  deleteParams(searchValue, searchName){
    const queryParamsManager = new QueryParamsManager
    const host = window.location.href.split('?')[0]
    const params = queryParamsManager.queryParamsTranslator(searchName, searchValue)
    window.location.href = queryParamsManager.buildUrl(host, params) 
  }
}