import { QueryParamsManager } from './QueryParamsManager'

export class ScheduleEventAreaModal {

  constructor(){
    this.originPath = window.location.pathname
    this.eventCardBtn = document.getElementsByClassName('event_type_card_btn')
    this.searchForm = document.getElementById('schedule_event_search_form')
    this.searchFormBtn = document.getElementsByClassName('schedule_event_search_form_btn')
    this.searchFormTarget = document.getElementById('schedule_event_search_target')

    this.childElements = document.getElementsByClassName('schedule_school_area')
    this.submitBtn = document.getElementById('schedule_event_search_form_school_area_btn')
    this.selected_regions = []
  }

  setSubmitEventSearchForm(){
    Array.from(this.searchFormBtn).forEach((element) => {
      element.addEventListener('click', () => this.searchForm.submit() )
    })
  }

  setScheduleEventAreaModal(){
    this.initLoad()
    this.setOpenEvent()
    this.setSearchFormValueEvent()
    this.setSubmitEvent()
  }

  initLoad(){
    const queryParamsManager = new QueryParamsManager()
    this.selected_regions = queryParamsManager.queryParamsfilter('prefecture_id_during')
    Array.from(this.childElements).forEach((element) => {
      if(this.selected_regions.includes(element.dataset.region)){
        element.parentNode.classList.add('is-on')
      }
    })
  }

  setOpenEvent(){
    Array.from(this.eventCardBtn).forEach( element => {
      const self = this
      element.addEventListener('click', function(){
        // remodal-wrapperはModalが存在しないと取得できないため
        const overlay = document.getElementsByClassName('remodal-wrapper')[0]
        const closeModalBtn = document.getElementById('schedules_area_modal_close_btn')
        const eventType = this.dataset.event_type

        self.dataSetUrl('/schedules/joint_day', self.searchForm)
        self.addEventTypeValue(eventType)
        self.setCloseEvent(overlay, closeModalBtn, eventType)
      })
    })
  }

  setCloseEvent(overlay, closeModalBtn, eventType){
    this.overlayClose(overlay, eventType)
    this.btnClose(closeModalBtn, eventType)
  }

  btnClose(closeModalBtn, eventType){
    closeModalBtn.addEventListener('click', () => {
      this.removeEventTypeValue(eventType)
      this.dataSetUrl(this.originPath, this.searchForm)
    })
  }

  overlayClose(overlay, eventType){
    overlay.addEventListener('click', (e) => {
      if(e.target.closest('.remodal-is-initialized') == null) {
        this.removeEventTypeValue(eventType)
        this.dataSetUrl(this.originPath, this.searchForm)
      }
    })
  }

  addEventTypeValue(eventType){
    const input = this.createInputTag('hidden', eventType, 'q[event_type_eq]')
    this.searchFormTarget.append(input)
  }

  removeEventTypeValue(eventType){
    const removeTarget = Array.from(this.searchFormTarget.childNodes).filter((removeElement) => {
      return removeElement.name === 'q[event_type_eq]' && removeElement.value === eventType
    })[0]
    if(removeTarget) return removeTarget.remove()
  }

  setSearchFormValueEvent(){
    Array.from(this.childElements).forEach((element) => {
      element.addEventListener('click', (e) => {
        const parent = e.target.parentNode
        const region = e.target.dataset.region
        if(this.selected_regions.includes(region)) {
          parent.classList.remove('is-on')
          this.removeSearchFormValue(region)
        } else {
          parent.classList.add('is-on')
          this.addSearchFormValue(region)
        }
      })
    })
  }

  removeSearchFormValue(deleteTargetRegion){
    const removeTarget = Array.from(this.searchFormTarget.childNodes).filter((removeElement) => removeElement.value == deleteTargetRegion)[0]
    this.selected_regions = this.selected_regions.filter(region => region !== deleteTargetRegion)
    removeTarget.remove()
  }

  addSearchFormValue(addTargetRegion){
    const input = this.createInputTag('hidden', addTargetRegion, 'q[prefecture_id_during][]')
    this.selected_regions.push(addTargetRegion)
    this.searchFormTarget.append(input)
  }

  setSubmitEvent(){
    if(this.submitBtn) this.submitBtn.addEventListener('click', () => this.searchForm.submit() )
    this.setSubmitEventSearchForm()
  }

  dataSetUrl(url, target){ target.action = url }

  createInputTag(type, value, name){
    const input = document.createElement('input')
    input.type = type
    input.value = value
    input.name = name
    return input
  }
}
